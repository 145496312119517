<template>
  <mobile-screen
    :header="true"
    :screen-class="
      $route.name !== 'r_plan-resources-help-online'
        ? 'plan-resources gray-bg  show-footer-only-on-last-screen'
        : 'plan-resources gray-bg'
    "
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_plan-resources-help-online'
        }"
      >
        <template v-slot:left>
          <button @click="backButton">
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          <template v-if="selectedLevel">
            {{ selectedLevel.name }}
          </template>
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @removeSelectedFilter="removeSelectedFilter"
        :hasFilters="true"
        :selectedFilters="selectedFilters"
        :routeName="'r_plan-search-filters'"
        @keyup.enter="submitSearch"
      ></search>
    </template>
    <ul
      v-if="
        selectedLevel &&
          !searchActiveNum &&
          !selectedResource &&
          !selectedFrequentResourceType &&
          selectedLevel.is_directly_declarable
      "
      class="clebex-item-section transparent no-border tiny-top-spacing"
    >
      <li class="clebex-item-section-item full-right-underline transparent">
        <router-link :to="{ name: 'r_plan-resources-slots' }" class="body-btn">
          {{ displayLabelName("plan", "resources", "go-directly-to-location") }}
        </router-link>
      </li>
    </ul>

    <ul
      v-if="
        (searchActiveNum && indicator) ||
          $store._state.data.searchFiltersPlanResources.selectedTypeIds.length >
            0
      "
      class="clebex-item-section tiny-top-spacing transparent no-border"
    >
      <li class="clebex-item-section-item full-right-underline transparent">
        <button class="body-btn" @click="getAnyResourceType()">
          {{
            displayLabelName(
              "plan",
              "resources",
              "get-any-resource-of-this-type"
            )
          }}
        </button>
      </li>
    </ul>
    <ul class="clebex-item-section pill title" v-if="resourceTypes">
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          {{
            displayLabelName("resources", "resource-types", "resource-types")
          }}
        </div>
      </li>
    </ul>
    <ul
      class="clebex-item-section pill section-scroll icon-app1"
      v-if="resourceTypes"
    >
      <!-- Favorite resource types -->
      <li
        class="clebex-item-section-item"
        v-for="resourceType in searchableResourceTypesFavorite"
        :key="resourceType.id"
      >
        <button
          @click="selectResourceType(resourceType)"
          class="clebex-item-content-wrapper"
        >
          <span class="follow-up-icons">
            <button
              @click.stop="unsetFavoriteResourceType(resourceType.id)"
              class="follow-up-icon-item"
            >
              <icon icon="#cx-app1-favourite-fill" />
            </button>
          </span>
          <span class="label">
            <span
              class="text"
              v-if="
                selectedLevel &&
                  selectedLevel.all_parents &&
                  selectedLevel.all_parents.length
              "
            >
              <span class="highlight">{{ `${resourceType.name}, ` }}</span>
              {{ displayParentNames(selectedLevel.all_parents) }}
            </span>
            <span class="text" v-else>
              <span class="highlight">{{ resourceType.name }}</span>
            </span>
          </span>
          <span
            v-if="
              // selectedFrequentResourceType &&
              //   selectedFrequentResourceType.id === resourceType.id
              $store._state.data.searchFiltersPlanResources.selectedTypeIds.includes(
                resourceType.id
              )
            "
            class="follow-up-icons"
          >
            <span
              class="follow-up-icon-item"
              :class="{
                active:
                  // selectedFrequentResourceType &&
                  // selectedFrequentResourceType.id === resourceType.id
                  $store._state.data.searchFiltersPlanResources.selectedTypeIds.includes(
                    resourceType.id
                  )
              }"
            >
              <icon icon="#cx-app1-checkmark" />
            </span>
          </span>
        </button>
      </li>
      <!-- Frequent resource types -->
      <li
        class="clebex-item-section-item"
        v-for="resourceType in searchableResourceTypesFrequent"
        :key="resourceType.id"
      >
        <button
          @click="selectResourceType(resourceType)"
          class="clebex-item-content-wrapper"
        >
          <span class="follow-up-icons">
            <button
              @click.stop="setFavoriteResourceType(resourceType.id)"
              class="follow-up-icon-item"
            >
              <icon icon="#cx-app1-favourite-bold" />
            </button>
          </span>
          <span class="label">
            <span
              class="text"
              v-if="
                selectedLevel &&
                  selectedLevel.all_parents &&
                  selectedLevel.all_parents.length
              "
            >
              <span class="highlight">{{ `${resourceType.name}, ` }}</span>
              {{ displayParentNames(selectedLevel.all_parents) }}
            </span>
            <span class="text" v-else>
              <span class="highlight">{{ resourceType.name }}</span>
            </span>
          </span>
          <span
            v-if="
              // selectedFrequentResourceType &&
              //   selectedFrequentResourceType.id === resourceType.id
              $store._state.data.searchFiltersPlanResources.selectedTypeIds.includes(
                resourceType.id
              )
            "
            class="follow-up-icons"
          >
            <span
              class="follow-up-icon-item"
              :class="{
                active:
                  // selectedFrequentResourceType &&
                  // selectedFrequentResourceType.id === resourceType.id
                  $store._state.data.searchFiltersPlanResources.selectedTypeIds.includes(
                    resourceType.id
                  )
              }"
            >
              <icon icon="#cx-app1-checkmark" />
            </span>
          </span>
        </button>
      </li>
      <!-- Rest resource types -->
      <li
        class="clebex-item-section-item"
        v-for="resourceType in searchableResourceTypesRest"
        :key="resourceType.id"
      >
        <button
          @click="selectResourceType(resourceType)"
          class="clebex-item-content-wrapper"
        >
          <span class="follow-up-icons">
            <button
              @click.stop="setFavoriteResourceType(resourceType.id)"
              class="follow-up-icon-item"
            >
              <icon icon="#cx-app1-favourite" />
            </button>
          </span>
          <span class="label">
            <span
              class="text"
              v-if="
                selectedLevel &&
                  selectedLevel.all_parents &&
                  selectedLevel.all_parents.length
              "
            >
              <span class="highlight">{{ `${resourceType.name}, ` }}</span>
              {{ displayParentNames(selectedLevel.all_parents) }}
            </span>
            <span class="text" v-else>
              <span class="highlight">{{ resourceType.name }}</span>
            </span>
          </span>
          <span
            v-if="
              // selectedFrequentResourceType &&
              //   selectedFrequentResourceType.id === resourceType.id
              $store._state.data.searchFiltersPlanResources.selectedTypeIds.includes(
                resourceType.id
              )
            "
            class="follow-up-icons"
          >
            <span
              class="follow-up-icon-item"
              :class="{
                active:
                  // selectedFrequentResourceType &&
                  // selectedFrequentResourceType.id === resourceType.id
                  $store._state.data.searchFiltersPlanResources.selectedTypeIds.includes(
                    resourceType.id
                  )
              }"
            >
              <icon icon="#cx-app1-checkmark" />
            </span>
          </span>
        </button>
      </li>
    </ul>

    <ul class="clebex-item-section pill icon-app1" v-if="hasResources">
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          {{ displayLabelName("resources", "resources", "resources") }}
        </div>
      </li>
      <div
        v-if="
          levelResources &&
            levelResources.data &&
            levelResources.data.length &&
            levelResources.data.filter(el => el.is_favorite)
        "
      >
        <li
          class="clebex-item-section-item"
          v-for="resource in levelResources.data.filter(el => el.is_favorite)"
          :key="resource.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectResource(resource)"
          >
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                :disabled="!canItem(resource, 'update')"
                @click.stop="unsetFavoriteResource(resource.id)"
              >
                <icon icon="#cx-app1-favourite-fill" />
              </button>
            </span>
            <span class="label">
              <span
                class="text"
                v-if="
                  selectedLevel &&
                    selectedLevel.all_parents &&
                    selectedLevel.all_parents.length
                "
              >
                <span class="highlight">{{ `${resource.name}, ` }}</span>
                {{ displayParentNames(selectedLevel.all_parents) }}
              </span>
              <span class="text" v-else
                ><span class="highlight">{{ resource.name }}</span></span
              >
            </span>
            <span class="follow-up-icons">
              <button
                @click.stop="
                  $router.push({
                    name: 'r_resource-level',
                    params: { resource_id: resource.id }
                  })
                "
                class="follow-up-icon-item"
              >
                <icon icon="#cx-app1-information" />
              </button>
              <span
                class="follow-up-icon-item"
                :class="{
                  active:
                    selectedResource && selectedResource.id === resource.id
                }"
              >
                <icon
                  icon="#cx-app1-checkmark"
                  v-if="selectedResource && selectedResource.id === resource.id"
                />
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  v-else
                />
              </span>
            </span>
          </button>
        </li>
        <li
          class="clebex-item-section-item"
          v-for="resource in levelResources.data.filter(
            el =>
              !el.is_favorite &&
              frequentLevelResources &&
              frequentLevelResources.data &&
              frequentLevelResources.data.find(e => e.id === el.id)
          )"
          :key="resource.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectResource(resource)"
          >
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                :disabled="!canItem(resource, 'update')"
                @click.stop="setFavoriteResource(resource.id)"
              >
                <icon icon="#cx-app1-favourite-bold" />
              </button>
            </span>
            <span class="label">
              <span
                class="text"
                v-if="
                  selectedLevel &&
                    selectedLevel.all_parents &&
                    selectedLevel.all_parents.length
                "
              >
                <span class="highlight">{{ `${resource.name}, ` }}</span>
                {{ displayParentNames(selectedLevel.all_parents) }}
              </span>
              <span class="text" v-else
                ><span class="highlight">{{ resource.name }}</span></span
              >
            </span>
            <span class="follow-up-icons">
              <button
                @click.stop="
                  $router.push({
                    name: 'r_resource-level',
                    params: { resource_id: resource.id }
                  })
                "
                class="follow-up-icon-item"
              >
                <icon icon="#cx-app1-information" />
              </button>
              <span
                class="follow-up-icon-item"
                :class="{
                  active:
                    selectedResource && selectedResource.id === resource.id
                }"
              >
                <icon
                  icon="#cx-app1-checkmark"
                  v-if="selectedResource && selectedResource.id === resource.id"
                />
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  v-else
                />
              </span>
            </span>
          </button>
        </li>
        <li
          class="clebex-item-section-item"
          v-for="resource in levelResources.data.filter(
            el =>
              !el.is_favorite &&
              (!frequentLevelResources ||
                !frequentLevelResources.data ||
                !frequentLevelResources.data.find(e => e.id === el.id))
          )"
          :key="resource.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="selectResource(resource)"
          >
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                :disabled="!canItem(resource, 'update')"
                @click.stop="setFavoriteResource(resource.id)"
              >
                <icon icon="#cx-app1-favourite" />
              </button>
            </span>
            <span class="label">
              <span
                class="text"
                v-if="
                  selectedLevel &&
                    selectedLevel.all_parents &&
                    selectedLevel.all_parents.length
                "
              >
                <span class="highlight">{{ `${resource.name}, ` }}</span>
                {{ displayParentNames(selectedLevel.all_parents) }}
              </span>
              <span class="text" v-else
                ><span class="highlight">{{ resource.name }}</span></span
              >
            </span>
            <span class="follow-up-icons">
              <button
                @click.stop="
                  $router.push({
                    name: 'r_resource-level',
                    params: { resource_id: resource.id }
                  })
                "
                class="follow-up-icon-item"
              >
                <icon icon="#cx-app1-information" />
              </button>
              <span
                class="follow-up-icon-item"
                :class="{
                  active:
                    selectedResource && selectedResource.id === resource.id
                }"
              >
                <icon
                  icon="#cx-app1-checkmark"
                  v-if="selectedResource && selectedResource.id === resource.id"
                />
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  v-else
                />
              </span>
            </span>
          </button>
        </li>
      </div>
    </ul>

    <template v-slot:footer>
      <plans-footer-menu><qr-code-scanner-button /></plans-footer-menu>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import PlansFooterMenu from "@/components/plans/PlansFooterMenu";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import PlanMixin from "@/services/mixins/plan/plan-mixin";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "PlanResources",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    PlansFooterMenu,
    QrCodeScannerButton,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: false,
      timer: null,
      notLoadedNum: 0,
      helpSlug: "plan-resources"
    };
  },
  computed: {
    ...mapState("level", ["levelResources", "frequentLevelResources"]),
    ...mapState("resource", [
      "resourceModels",
      "resourceTypes",
      "resourceAttributes",
      "frequentResourceTypes",
      "resourceFunctionTypes"
    ]),
    ...mapState("searchFiltersPlanResources", [
      "selectedTypeIds",
      "selectedModelIds",
      "selectedAttributesIds",
      "selectedCapacity",
      "searchActiveNum"
    ]),
    hasResources() {
      return !!(
        this.levelResources &&
        this.levelResources.data &&
        this.levelResources.data.length
      );
    },
    hasFrequentResourceTypes() {
      if (this.searchQuery || this.indicator) return null;
      return !!(
        this.frequentResourceTypes && this.frequentResourceTypes.length
      );
    },
    hasFrequentResources() {
      if (this.searchQuery || this.indicator) return null;
      return !!(
        this.frequentLevelResources &&
        this.frequentLevelResources.data &&
        this.frequentLevelResources.data.length
      );
    },
    indicator() {
      return this.selectedFilters.length;
    },
    selectedFilters() {
      let filters = [];
      const {
        resourceTypes,
        selectedTypeIds,
        resourceModels,
        selectedModelIds,
        resourceAttributes,
        selectedAttributesIds,
        selectedCapacity
      } = this;
      if (
        resourceTypes &&
        resourceTypes.length &&
        selectedTypeIds &&
        selectedTypeIds.length
      ) {
        const filtered = resourceTypes.filter(
          item => selectedTypeIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceType" }];
        });
      }

      if (
        resourceModels &&
        resourceModels.data &&
        resourceModels.data.length &&
        selectedModelIds &&
        selectedModelIds.length
      ) {
        const filtered = resourceModels.data.filter(
          item => selectedModelIds.indexOf(item.id) !== -1
        );
        filters = [...filters, ...filtered];
      }

      if (
        resourceAttributes &&
        resourceAttributes.length &&
        selectedAttributesIds &&
        selectedAttributesIds.length
      ) {
        const filtered = resourceAttributes.filter(
          item => selectedAttributesIds.indexOf(item.id) !== -1
        );
        filtered.forEach(item => {
          filters = [...filters, { ...item, type: "resourceAttribute" }];
        });
      }

      if (selectedCapacity) {
        filters.push({
          id: "cap1",
          name:
            this.displayLabelName("resources", "resources", "capacity") +
            " " +
            selectedCapacity,
          type: "capacity"
        });
      }

      return filters;
    },
    searchQuery: {
      get() {
        return this.$store.state.search.searchQuery;
      },
      set(value) {
        this.$store.commit("search/setSearchQuery", value, {
          root: true
        });
      }
    },
    resourceModelDeclareAndBookingIds() {
      const { resourceModels } = this;
      const ids = [];
      if (resourceModels && resourceModels.data && resourceModels.data.length) {
        resourceModels.data.forEach(item => {
          if (item.model === "DECLARE" || item.model === "BOOKING") {
            ids.push(item.id);
          }
        });
      }
      return ids;
    },
    resourceFunctionBookingId() {
      const { resourceFunctionTypes } = this;
      if (
        resourceFunctionTypes &&
        resourceFunctionTypes.data &&
        resourceFunctionTypes.data.length
      ) {
        return resourceFunctionTypes.data.filter(
          item => item.type === "BOOKING_PLAN"
        )[0].id;
      }
      return null;
    },
    queryParams() {
      return this.$route.query;
    },
    searchableResourceTypesFavorite() {
      if (
        this.resourceTypes &&
        this.resourceTypes &&
        this.resourceTypes.length
      ) {
        return this.resourceTypes
          .filter(el => el.is_searchable && el.is_favorite)
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (b.name < a.name) {
              return 1;
            }
            return 0;
          });
      }

      return [];
    },
    searchableResourceTypesFrequent() {
      if (
        this.resourceTypes &&
        this.resourceTypes.length &&
        this.frequentResourceTypes &&
        this.frequentResourceTypes.length
      ) {
        return this.resourceTypes
          .filter(
            el =>
              el.is_searchable &&
              !el.is_favorite &&
              this.frequentResourceTypes.find(e => e.id === el.id)
          )
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (b.name < a.name) {
              return 1;
            }
            return 0;
          });
      }

      return [];
    },
    searchableResourceTypesRest() {
      if (this.resourceTypes && this.resourceTypes.length) {
        if (this.frequentResourceTypes && this.frequentResourceTypes.length) {
          return this.resourceTypes
            .filter(
              el =>
                el.is_searchable &&
                !el.is_favorite &&
                !this.frequentResourceTypes.find(e => e.id === el.id)
            )
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (b.name < a.name) {
                return 1;
              }
              return 0;
            });
        } else {
          return this.resourceTypes
            .filter(el => el.is_searchable && !el.is_favorite)
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (b.name < a.name) {
                return 1;
              }
              return 0;
            });
        }
      }

      return [];
    }
  },
  watch: {
    // searchQuery() {
    //   if (this.timer) {
    //     clearTimeout(this.timer);
    //     this.timer = null;
    //   }
    //   this.timer = setTimeout(() => {
    //     this.submitSearch();
    //   }, 300);
    // },
    searchActiveNum() {
      this.submitSearch();
    },
    selectedLevel() {
      this.$store.dispatch("resource/setResourceTypes", null, { root: true });
      this.getDeclarativeResources();
    },
    queryParams() {
      this.handleQueryString();
    },
    hasResources() {
      this.handleQueryString();
    },
    levelResources() {
      if (
        this.levelResources &&
        this.levelResources.data &&
        !this.levelResources.data.length
      ) {
        this.notLoadedNum++;
      } else {
        if (this.notLoadedNum !== 0) {
          this.notLoadedNum = 0;
        }
      }
    },
    frequentResourceTypes() {
      if (this.frequentResourceTypes && !this.frequentResourceTypes.length) {
        this.notLoadedNum++;
      } else {
        if (this.notLoadedNum !== 0) {
          this.notLoadedNum = 0;
        }
      }
    },
    frequentLevelResources() {
      if (
        this.frequentLevelResources &&
        this.frequentLevelResources.data &&
        !this.frequentLevelResources.data.length
      ) {
        this.notLoadedNum++;
      } else {
        if (this.notLoadedNum !== 0) {
          this.notLoadedNum = 0;
        }
      }
    },
    notLoadedNum() {
      if (this.notLoadedNum === 3) {
        this.$router.push({ name: "r_plan-slots" });
      }
    }
  },
  created() {
    this.$store.dispatch("resource/setResourceTypes", null, { root: true });
    this.setPaginationData(null);
    if (!this.selectedLevel && !this.selectedResource) {
      this.$router.push({ name: "r_plan" });
    } else {
      this.getDeclarativeResources();
    }
  },
  methods: {
    ...mapActions("level", ["getLevelResources"]),
    ...mapActions("resource", [
      "getResourceTypes",
      "favoriteResource",
      "unFavoriteResource",
      "favoriteResourceType",
      "unFavoriteResourceType"
    ]),
    ...mapActions("searchFiltersPlanResources", [
      "setSelectedTypeIds",
      "setSelectedModelIds",
      "setSelectedAttributeIds",
      "setSelectedCapacity"
    ]),
    ...mapActions("plan", ["selectPlanLevel", "selectPlanResourceType"]),
    ...mapActions("pagination", ["setPaginationData"]),
    getDeclarativeResources() {
      this.setPaginationData(null);
      if (this.selectedLevel) {
        const resourceTypesParams = {
          filters: [
            {
              type: "is_searchable",
              value: 1
            },
            {
              type: "resource",
              filters: [
                {
                  type: "level",
                  value: this.selectedLevel.id,
                  options: {
                    mode: "INDIRECT"
                  }
                },
                {
                  type: "resource_function_type",
                  value: [1]
                },
                {
                  type: "resource_model",
                  value: [2, 3]
                }
              ]
            }
          ],
          sort: ["FAVORITE"]
        };
        this.getResourceTypes(resourceTypesParams);
        this.getResourceTypes({
          ...resourceTypesParams,
          filters: [
            ...resourceTypesParams.filters,
            {
              type: "frequent",
              value: 3,
              options: {
                level: this.selectedLevel.id,
                mode: "INDIRECT"
              }
            }
          ]
        });
        if (this.searchQuery || this.resourceTypes) {
          const params = {
            mode: "INDIRECT",
            sort: "FAVORITES"
          };

          if (this.resourceModelDeclareAndBookingIds.length) {
            if (params.filters) {
              params.filters.push({
                type: "resource_model",
                value: this.resourceModelDeclareAndBookingIds
              });
            } else {
              params.filters = [
                {
                  type: "resource_model",
                  value: this.resourceModelDeclareAndBookingIds
                }
              ];
            }
          }

          if (this.resourceFunctionBookingId) {
            if (params.filters) {
              params.filters.push({
                type: "resource_function_type",
                value: this.resourceFunctionBookingId
              });
            } else {
              params.filters = [
                {
                  type: "resource_function_type",
                  value: this.resourceFunctionBookingId
                }
              ];
            }
          }

          if (this.searchQuery) {
            params.query = this.searchQuery;
          }
          if (this.resourceTypes && this.resourceTypes.length) {
            params.resource_type_ids = this.resourceTypes.map(item => item.id);
          }

          this.getLevelResources({
            levelId: this.selectedLevel.id,
            params
          });
        } else {
          const params = { mode: "INDIRECT", sort: "FAVORITES" };
          if (this.resourceModelDeclareAndBookingIds.length) {
            if (params.filters) {
              params.filters.push({
                type: "resource_model",
                value: this.resourceModelDeclareAndBookingIds
              });
            } else {
              params.filters = [
                {
                  type: "resource_model",
                  value: this.resourceModelDeclareAndBookingIds
                }
              ];
            }
          }

          if (this.resourceFunctionBookingId) {
            if (params.filters) {
              params.filters.push({
                type: "resource_function_type",
                value: this.resourceFunctionBookingId
              });
            } else {
              params.filters = [
                {
                  type: "resource_function_type",
                  value: this.resourceFunctionBookingId
                }
              ];
            }
          }
          // frequent resources
          this.getLevelResources({
            levelId: this.selectedLevel.id,
            params: {
              ...params,
              frequent: 3
            }
          });
          // resources
          this.getLevelResources({
            levelId: this.selectedLevel.id,
            params: {
              ...params
            }
          });
        }
      }
    },
    getAnyResourceType() {
      this.selectPlanResource(null);
      this.selectPlanFrequentResourceType(null);
      this.selectPlanResourceType(null);
      this.selectResourceType(this.selectedTypeIds);
      if (
        this.levelResources &&
        this.levelResources.data &&
        this.levelResources.data.length
      ) {
        let resources = [];

        for (var i = 0; i < this.levelResources.data.length; i++) {
          if (
            this.selectedTypeIds.includes(
              this.levelResources.data[i].resource_type_id
            )
          ) {
            resources.push(this.levelResources.data[i].id);
          }
        }

        this.$router.push({ name: "r_plan-resources" });
        setTimeout(() => {
          this.$store.commit("plan/setAnyResourceTypes", resources, {
            root: true
          });
          this.$router.push({ name: "r_plan-resources-slots" });
        });
      }
    },
    setFavoriteResource(id) {
      this.favoriteResource(id)
        .then(() => {
          setTimeout(() => {
            this.getDeclarativeResources();
          }, 1000);
        })
        .catch(error => {
          errorHandler(error.response);
        });
    },
    unsetFavoriteResource(id) {
      this.unFavoriteResource(id)
        .then(() => {
          setTimeout(() => {
            this.getDeclarativeResources();
          }, 1000);
        })
        .catch(error => {
          errorHandler(error.response);
        });
    },
    setFavoriteResourceType(id) {
      this.favoriteResourceType(id)
        .then(() => {
          setTimeout(() => {
            this.getDeclarativeResources();
          }, 1000);
        })
        .catch(error => {
          errorHandler(error.response);
        });
    },
    unsetFavoriteResourceType(id) {
      this.unFavoriteResourceType(id)
        .then(() => {
          setTimeout(() => {
            this.getDeclarativeResources();
          }, 1000);
        })
        .catch(error => {
          errorHandler(error.response);
        });
    },
    selectResource(resource) {
      this.$router.push({ name: "r_plan-resources" });
      setTimeout(() => {
        this.selectPlanFrequentResourceType(null);
        this.selectPlanResourceType(null);
        this.selectPlanResource(resource);
        this.$router.push({ name: "r_plan-resources-slots" });
      });
    },
    submitSearch() {
      this.getDeclarativeResources();
    },
    toggleSearch() {
      this.$store.commit("searchFiltersPlanResources/clearAllFilters", null, {
        root: true
      });
      this.search = !this.search;
    },
    selectResourceType(type) {
      this.search = true;

      setTimeout(() => {
        if (
          this.$store._state.data.searchFiltersPlanResources.selectedTypeIds.includes(
            type.id
          )
        ) {
          this.$store._state.data.searchFiltersPlanResources.selectedTypeIds.splice(
            this.$store._state.data.searchFiltersPlanResources.selectedTypeIds.indexOf(
              type.id
            ),
            1
          );
        } else {
          this.$store._state.data.searchFiltersPlanResources.selectedTypeIds.push(
            type.id
          );
        }

        this.getDeclarativeResources();
      });
    },
    handleQueryString() {
      const { resourceId } = this.queryParams;
      const { levelResources } = this;
      if (resourceId) {
        if (
          levelResources &&
          levelResources.data &&
          levelResources.data.length
        ) {
          const filtered = levelResources.data.filter(
            item => item.id === Number(resourceId)
          );
          if (filtered && filtered.length) {
            this.$router.push({ name: "r_plan-resources" });
            setTimeout(() => {
              this.selectPlanResourceType(null);
              this.selectPlanFrequentResourceType(null);
              this.selectPlanResource(filtered[0]);
              this.$router.push({ name: "r_plan-resources-slots" });
            });
          }
        }
      }
    },
    removeSelectedFilter(filter) {
      this.$store.commit("searchFiltersPlanResources/activateSearch", true, {
        root: true
      });

      if (filter.model) {
        return this.setSelectedModelIds(filter.id);
      }

      if (filter.type === "resourceAttribute") {
        return this.setSelectedAttributeIds(filter.id);
      }

      if (filter.type === "resourceType") {
        return this.setSelectedTypeIds(filter.id);
      }

      if (filter.type === "capacity") {
        return this.setSelectedCapacity(0);
      }
    },
    backButton() {
      this.selectPlanLevel(null);
      this.$router.push({
        name: "r_plan",
        params: this.$route.params
      });
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.getDeclarativeResources();
    }
  },
  beforeUnmount() {
    // reset data
    // resource search query
    this.$store.commit("search/setSearchQuery", "", {
      root: true
    });
    // level resources + frequent level resources
    this.$store.commit("level/setLevelResources", null, { root: true });
    this.$store.commit("level/setFrequentLevelResources", null, { root: true });
    // resource types
    this.$store.commit("resource/setSelectedFrequentResourceTypes", null, {
      root: true
    });
    this.$store.commit("resource/setSelectedResourceTypes", null, {
      root: true
    });
    // filters selected models & filters selected types
    this.$store.commit("searchFiltersPlanResources/clearAllFilters", "", {
      root: true
    });
    // reset screen num, plan and resource selection
    this.selectPlanResource(null);
    this.selectPlanFrequentResourceType(null);
    this.selectPlanResourceType(null);
  },
  mixins: [PlanMixin, helpOnlineMixin]
};
</script>
